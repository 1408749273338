<template>
  <div class="order__test-detail_wrapper">
    <div
      @click="pushToTest(testDetail[activeImage])"
      class="order__test-detail_container">
      <div>
        <div>
          <div class="order__test-detail_title">
            {{home.test_detail_title_mobile[language]}}
          </div>
          <div class="order__test-detail_text">
            {{home.test_detail_text_mobile[language]}}
          </div>
        </div>
        <div class="order__test-detail_icon_wrapper">
          <img src="@/assets/pictures/order/lastVers/detail1_crop.webp"
               v-show="activeImage === 0"
               class="order__test-detail_icon order__test-detail_icon_1" />
          <img class="order__test-detail_icon order__test-detail_icon_2"
               v-show="activeImage === 1"
               src="@/assets/pictures/order/lastVers/detail2_crop.webp" />
          <img class="order__test-detail_icon order__test-detail_icon_3"
               v-show="activeImage === 2"
               src="@/assets/pictures/order/lastVers/detail3_crop.webp" />
          <img class="order__test-detail_icon order__test-detail_icon_4"
               v-show="activeImage === 3"
               src="@/assets/pictures/order/lastVers/detail4_crop.webp" />
        </div>
        <RoundBorder class="order__test-detail_border" />
      </div>
    </div>
  </div>
</template>

<script>
import home from '@/assets/text/home.json';
import RoundBorder from '@/assets/pictures/borders/23.svg';

export default {
  name: 'LoadTest',
  data() {
    return {
      home,
      activeImage: 0,
      testDetail: [
        'be0a15b8-7c1a-4c0c-996a-1ae0d8a3f3c9',
        '148e4a6c-21a1-4573-9760-7f018cb4ab0a',
        '9388d8f0-8bac-4bef-abd5-c4c29deb36de',
        '956f330f-82af-4f51-8177-c6678dde52ac',
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    pushToTest(data) {
      this.$router.push(`/test-order-offers/${data}`);
    },
  },
  mounted() {
    this.$store.watch((state) => state.home.activeImage, () => {
      this.activeImage = this.$store.state.home.activeImage;
    });
  },
  components: {
    RoundBorder,
  },
};
</script>
